<template>
  <section class="form">
    <LoadingSpinner v-if="loading"/>
    <v-form class="the_form_wrapper" @submit.prevent="submit" v-model="valid" v-else>
            <p class="form_head">
              سجل الان واذا تم قبولك
              سوف يتم التواصل معك خلال 24 ساعه
            </p>
       <v-text-field
          label="الاسم الثلاثي"
          v-model="form_data.first_name"
          filled
          rounded
          dense
          background-color="#fff"
          append-icon="mdi-account"
          reverse
          :rules="nameRules"
      ></v-text-field>

      <!-- email -->
      <v-text-field
          v-model="form_data.email"
          label="البريد الالكتروني"
          filled
          rounded
          dense
          background-color="#fff"
          append-icon="mdi-email-alert"
          reverse
          :rules="emailRules"

      ></v-text-field>

      <!-- phone -->
      <v-row dense>
        <v-col cols="3" class="pl-0">
          <vue-country-code
              :defaultCountry="form_data.country"
              :disabledFetchingCountry="false"
              ref="vcc"
              @onSelect="onSelect">
          </vue-country-code>
        </v-col>
        <v-col cols="9">
          <v-text-field
              v-model="form_data.phone"
              label="رقم الهاتف"
              filled
              rounded
              dense
              background-color="#fff"
              reverse
              :rules="phoneRules"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- age -->
      <v-text-field
          v-model="form_data.age"
          label="العمر"
          filled
          rounded
          dense
          background-color="#fff"
          append-icon="mdi-account"
          reverse
          type="number"
      ></v-text-field>

      <div class="the_button">
        <button class="form">
          <span v-if="!loading_submit">
            سجل الان
          </span>
          <v-progress-circular
              v-else
              size="24"
              color="info"
              indeterminate
          ></v-progress-circular>
        </button>
        <p class="red" v-if="error">{{ error }}</p>
      </div>
      <p class="notic">لن يتم مشاركة معلوماتك مطلقا مع أي طرف ثالث.</p>
    </v-form>
  </section>
</template>

<script>
import VueCountryCode from "vue-country-code";
import {get_user_country, insert_data} from "@/api/general";
import LoadingSpinner from "./LoadingSpinner";

export default {
  components:{LoadingSpinner, VueCountryCode,},
  data: () => ({
    loading_submit: false,
    loading: false,
    valid: false,
    error: '',
    form_data: {
      first_name: null,
      last_name: null,
      email: null,
      phone: '',
      country: null,
      source: null,
      page_name: null,
    },
    nameRules: [
      v => !!v || 'برجاء ادخال الاسم',
    ],
    emailRules: [
      v => !!v || 'برجاء ادخال البريد الالكتروني',
      v => /.+@.+/.test(v) || 'برجاء ادخال البريد الالكتروني',
    ],
    phoneRules: [
      v => !!v || 'برجاء ادخال رقم الهاتف',
      v => v.length > 5 || 'يجب ان يكون الهاتف اكبر من 5 ارقام',
      v => v.length <= 10 || 'يجب ان يكون الهاتف اقل من 10 ارقام',
    ]
  }),
  methods: {
    onSelect({iso2}) {
     this.form_data.country = iso2
    },
    showForm() {
      this.loading = true;
      get_user_country()
      .then(response => {
        this.form_data.country = response.data;
        this.form_data.source = window.location.search.substring(1);
        this.form_data.page_name = window.location.pathname.substring(1);
        this.loading = false;
        this.pressedRegister = true;
      })
    },
    submit() {
      if(this.valid) {
        this.loading_submit = true;
        insert_data(this.form_data).then(response => {
          this.loading_submit = false;
          console.log(response);
          window.location.href = '/well-done'
        }).catch((err) => { this.error = err.response.data.msg; this.loading_submit = false; })
      }
    }
  },
  created() {
    this.showForm();
  }
}
</script>

<style scoped>
.vue-country-select {
  width: 100%;
  border: 0;
  background: #fff;
  border-radius: 15px;
}
/deep/.dropdown {
  height: 54px !important;
  border-radius: 15px;
  width: 100% !important;
}
</style>